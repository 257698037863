import React, { useState, useEffect, useReducer} from 'react'

import {

  CToast,
  CToastBody,
  CToastHeader,
  CToaster,

} from '@coreui/react'

import { green1 } from '../../helpers/palette'

const reducer = (state, action) => {
  switch (action.type) {
    case 'push':
      return [...state, { ...action.payload, show: true, }]
    default:
      throw new Error();
  }
}


const ToastMessage = (props) => {
  //array de toasts
  const [toasts, dispath] = useReducer(reducer,[])

  const {toast} = props

  useEffect(() => {
    if (toast.key > 0) {
      dispath({type:'push', payload:{...toast} })
    }
  }, [toast.key])

  return (
    <CToaster position="top-center">
      {
        toasts.length > 0 ? toasts.map(t => {
          return (
            <CToast
              key={t.key}
              show={t.show}
              autohide={3000}
              fade={true}
            >
              <CToastHeader closeButton={true} style={{ background: t?.color ? t?.color : green1, color: 'white' }}>
                {t.title}
              </CToastHeader>
              <CToastBody>
                {t.message}
              </CToastBody>
            </CToast>
          )
        }) : <></>
      }
    </CToaster>
  )
}

export default ToastMessage