import login from './login.reducer'
import config from './config.reducer'
import enviroment from './enviroment.reducer'
import dashboard from './dashboard.reducer'
import countDown from './countDown.reducer'
import viewers from './viewers.reducer'
import { combineReducers } from 'redux'


const all = combineReducers({
    login: login,
    config: config,
    enviroment: enviroment,
    dashboard: dashboard,
    countDown: countDown,
    viewers:viewers
})

export default all