import { types } from "../types/types"

const initialState = {
    Agenda: "",
    Estado: false,
    Inicio: 0,
    Tiempo: 0
}

const countDown = (state = initialState, action) => {
    switch (action.type) {
        case types.setCountDown:
            return action.payload
        default:
            return state
    }
}

export default countDown