import { types } from "../types/types"

const initialState = {
    total: 0
}

const online_viewers = (state = initialState, { payload, type }) => {
    
    switch (type) {
        case (types.setViewers):
            return { ...state, total: payload }

        default:
            return state
    }
}

export default online_viewers