
export const IP_SERVER = "https://asambleageneral-314d8-default-rtdb.firebaseio.com" //"10.60.35.245"
// export const IP_SERVER = "https://asambleageneral-d7e87-default-rtdb.firebaseio.com" //"10.60.35.245"

export const IP_APiSERVER = process.env.REACT_APP_API_URL //"10.60.35.245"


export const IP_LOCAL = "http://localhost:3000"

export const SYSTEM_NAME = "MIESPACIO"

export const SYSTEM_TITLE = "COOPERATIVA DE AHORRO Y CRÉDITO SALCAJÁ R.L"

