import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import firebase from '../../libraries/Firebase';
import { useDispatch } from 'react-redux';
import { setLogin } from '../../actions/login.action'
import { useHistory } from 'react-router';
import { getRequest, postRequest } from '../../libraries/Functions';
import { setEnviromentValue } from '../../actions/enviroment.action';
import ToastMessage from '../../components/toast/toast';
import { blueButton } from '../../assets/css/global';
import { blue3 } from '../../helpers/palette';
import {
  FadingCircle,
} from 'better-react-spinkit'
import { hash } from '../../libraries/Functions';
import { HiUser, HiLockClosed } from "react-icons/hi"
// import logo from '../../assets/images/Logo.png'

const Login = () => {
  const [error, setError] = useState(0)
  const [assemblyLoad, setAssemblyLoad] = useState(true)
  const [state, setState] = useState({ logoPath: "", loadingImage: true })
  const [toast, setToast] = useState({ key: 0, title: '', message: '' })
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState({ Password: "", User: "" })
  const dispatch = useDispatch()
  const history = useHistory()

  const createToast = (_title, _message, _color) => setToast(c => ({ key: c.key + 1, title: _title, message: _message, color:_color }))


  // Obtiene el logo y la asamblea activa al iniciar, al obtener todo habilita el inicio de sesion
  useEffect(() => {
    firebase.database().ref('/Logo').once('value', snapshot => {
      const Logo = snapshot.val()
      if (Logo != undefined) {
        const storage = firebase.storage()
        storage.ref(`${Logo}`).getDownloadURL().then((url) => {
          dispatch(setEnviromentValue({ logo: url }))
          setState(c => ({ ...c, logoPath: url, loadingImage: false }))
        })
      }
    })

    firebase.database().ref(`/created_name_convention`).once(`value`, snapshot => {
      const result = snapshot.val()
      if (result !== undefined && result !== null) {
        Object.entries(result).forEach(([key, value]) => {
          if (result[key].active === true) {
            dispatch(setEnviromentValue({ assembly: key }))
          }
        })
      } else dispatch(setEnviromentValue({ assembly: null }))
      setAssemblyLoad(false)
    })
  }, [])

  // Verifica las credenciales y loguea en caso de verificacin correcta, redirecciona al menu
  const newSession = async () => {
    dispatch(setLogin({ user: inputs.User }))
    // history.push("/")
    setLoading(true)
    const userRef = firebase.database().ref(`/UsuariosWeb/${inputs.User}`)
    userRef.get().then((snapshot) => {
      setLoading(false)
      const userData = snapshot.val()
      if (userData === null) createToast("Alerta", "Credenciales erroneas","#c23616")
      else if (userData.Password === hash(inputs.Password) && userData.Activo === true) {
        const permissionRef = firebase.database().ref(`/PerfilesUsuario/${userData.Rol}`)
          permissionRef.get().then(_per => {
          dispatch(setLogin({ login: true, user: userData, permissions: findNavPermissions(_per.val()) }))
          history.push("/")
        })
      }
      else return createToast("Alerta", "Credenciales erroneas","#c23616")
    })
    // createToast("Alerta", "Error de red!")
    setLoading(false)
  }

  //Retorna los permisos del usuario 
  const findNavPermissions = (_data) => {
    var tempPermission = []

    const getPermissionsFromBranches = _obj => {
      if (_obj.hasOwnProperty('_sub')) {
        Object.entries(_obj._sub).forEach(([key, val]) => {
          tempPermission.push(key)
          getPermissionsFromBranches(val)
        })
      }

      if (_obj.hasOwnProperty('_components')) {
        Object.entries(_obj._components).forEach(([key, val]) => {
          if (val === true) tempPermission.push(key)
        })
      }
    }

    if (_data !== null && _data !== undefined) {
      Object.entries(_data).forEach(([key, value]) => {
        tempPermission.push(key)
        getPermissionsFromBranches(value)

      })
    }
    return tempPermission
  }



  return (
    <div className="c-app c-default-layout flex-row align-items-center fondologin" >
      <ToastMessage toast={toast} />
      <CContainer>

        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>

              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Inicio de sesión</h1>
                    <p className="text-muted">Accede a través de tus credenciales</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <HiUser /> </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" placeholder="Usuario" value={inputs.User} onChange={({ target }) => setInputs(current => ({ ...current, User: target.value }))} />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <HiLockClosed /> </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder="Contraseña" value={inputs.Password} onChange={({ target }) => setInputs(current => ({ ...current, Password: target.value }))}
                      onKeyPress={
                        (e)=>{
                          if (
                            e.key ===
                                "Enter" 
                        ) {
                           newSession();
                        }
                        }
                      }
                      />
                    </CInputGroup>
                    <CRow>
                      {
                        loading || assemblyLoad ?
                          <CCol xs="6">
                            <FadingCircle />
                          </CCol> :
                          <CCol xs="6">
                            <CButton style={blueButton} className="px-4" onClick={newSession}>Iniciar sesión</CButton>
                          </CCol>
                      }
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white py-5 d-md-down-none" style={{ width: '44%', backgroundColor: blue3 }}>
                <CCardBody className="text-center">
                  {state.loadingImage ? <center className="load"> <FadingCircle color="white" size={100} /></center> : <img src={state.logoPath} style={{ maxWidth: '100%', maxHeight: "50%" }} />}
                  <div>
                    <h3>Cooperativa Salcajá RL.</h3>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
