import { types } from "../types/types"

const initialState = { logo:""}

const enviroment = (state = initialState, action) => {
    switch (action.type) {
        case types.setEnviromentValue:
            return { ...state, ...action.payload }
        default:
            return state
    }
}

export default enviroment