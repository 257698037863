import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

var firebaseConfig = {
    apiKey: 'AIzaSyCRk92ZMi2sYtHWuH2p2hQC7qbLmUND4uc',
    authDomain: 'asambleageneral-d7e87.firebaseapp.com',
    databaseURL: 'https://asambleageneral-d7e87-default-rtdb.firebaseio.com',
    projectId: 'asambleageneral-d7e87',
    storageBucket: 'asambleageneral-d7e87.appspot.com',
    messagingSenderId: '994241614597',
    appId: '1:994241614597:web:f98d75bba837edbf229d6c',
    measurementId: 'G-ETN4HKZQ7S',

    // apiKey: 'AIzaSyCeZjkP237icX5T3NZLPHPClbBGF6qmSnc',
    // authDomain: 'asambleageneral-314d8.firebaseapp.com',
    // databaseURL: 'https://asambleageneral-314d8-default-rtdb.firebaseio.com',
    // projectId: 'asambleageneral-314d8',
    // storageBucket: 'gs://asambleageneral-314d8.appspot.com',

    // messagingSenderId: '5284122877',
    // appId: '1:5284122877:web:1855c4be2f141c78c3ee94',
    // measurementId: 'G-77LCZ83BY2',

    // apiKey: 'AIzaSyBx_zVnS22G5i9iZA86DzA49Isrw2of258',
    // authDomain: 'asamblea-prepro.firebaseapp.com',
    // databaseURL: 'https://asamblea-prepro-default-rtdb.firebaseio.com',
    // projectId: 'asamblea-prepro',
    // storageBucket: 'asamblea-prepro.appspot.com',
    // messagingSenderId: '25845212044',
    // appId: '1:25845212044:web:358cf67b6d6dcc3afeeca9',
    // measurementId: 'G-VTNNK9CLQB',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
