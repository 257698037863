const green1="#46B27E"
const green2="#5Bb135"
const green3="#2BA637"

const blue1="#1E8794"
const blue2="#18739D"
const blue3="#14427E"
const blue4="#253676"

const red="#c23616"

const gray="#EAEAEA"

export{
    green1,
    green2,
    green3,
    blue1,
    blue2,
    blue3,
    blue4,
    red,
    gray
}