import {
    green2,
    green3,
    blue3,
    red,
    gray
} from '../../helpers/palette'

const blueButton = {
    color: 'white',
    backgroundColor: blue3
}


const greenButton = {
    color: 'white',
    backgroundColor: green2,


}

const redButton = {
    color: 'white',
    backgroundColor: red
}

const grayButton = {
    backgroundColor: gray
}
const background ={
    backgroundColor: "#14427e",
    color: 'white',
}

const buttonnum={

    borderRadius: "2px",
    padding: "10px",
     backgroundColor: "#01AB00",
     color:"#f5f6fa"
}
const buttonnummarc={

    borderRadius: "2px",
    padding: "10px",
    borderColor:red,
    borderWidth:"5px",
     backgroundColor: "#01AB00",
     color:"#f5f6fa"
}
const modalHeader = {
    backgroundColor: blue3,
    color: 'white'
}

const cardHeader = {
    backgroundColor: green3,
    color: 'white'
}

const icons = { background: green3, color: 'white' }


export {
    blueButton,
    greenButton,
    redButton,
    grayButton,
    modalHeader,
    cardHeader,
    icons,
    background,
    buttonnum,
    buttonnummarc
}