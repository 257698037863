import { types } from "../types/types"

const initialState = { ImagePath: '' }

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case types.setImagePath:
            return { ImagePath: action.payload.ImagePath }
        default:
            return state
    }
}

export default dashboard