export const types = {

    setLogin:"[Login] setLogin",
    checkLogin:"[Login] checkLogin",
    closeLogin:"[Login] closeLogin",
    
    setEnviromentValue:"[Enviroment] setEnviromentValue",
    updateEnviromentValue:"[Enviroment] updateEnviromentValue",
    setImagePath:"[Dashboard] setImagePath",
    setLogoImagePath:"[Logo] setLogoImagePath",
    
    setCountDown:"[CountDown] setCountDown",

    setViewers:'[Viewers Count]set Viewers'
}