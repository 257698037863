import {types} from '../types/types'

export const setLogin=(_payload)=>({
    type:types.setLogin,
    payload:_payload
})

export const closeLogin=()=>({
    type:types.closeLogin,
    payload:{}
})