import { types } from "../types/types"

const initialState = { login: false, user: {}, permissions: [] }

const login = (state = initialState, action) => {
    switch (action.type) {
        case types.setLogin:
            return { login: true, user: action.payload.user, permissions: action.payload.permissions }
        case types.closeLogin:
            return initialState
        default:
            return state
    }
}

export default login